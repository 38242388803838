const workExp = [
    {
      title: 'Junior Front-End Engineer', 
      company: 'Chelsea Avondale', 
      location: 'Kitchener, ON', 
      date: 'April 2022 - October 2022', 
      text: '• Developed 3 major reusable components in JavaScript within a month that enhanced performance by 30%.',
      textTwo: '• Utilized Python and Flask to host web servers for our front-end systems',
      textThree: '• Developed and Debugged JavaScript code during the SDLC',
      textFour: '• Used a MERN stack structure for our frontend and backend environments',
      textFive: '•	Worked on utilizing containers with Docker which will increase scalability by 50%'
    },
    {
      title: 'Intern Web Developer',
      company: 'Toronto General Hospital - Advanced Perioperative Imaging Lab',
      location: 'Toronto, ON',
      date:
        'May 2019 - April 2022',
      image:
        "https://apil.ca/wp-content/uploads/2021/09/UHNF-Hosp-webheader-1.png",
      imgTitle: "UHN",
      text: 
      "• Developed 3D Web GL viewers in Vanilla JavaScript.",
      textTwo: "• Made the switch from Vanilla JavaScript to ReactJS. This increased performance by 40% and significantly decreased load times.",
      textThree: "• Utilized AWS S3 Buckets to host webpages early on in SDLC.",
      textFour: "• Deep into development, CI systems such as Github Actions were integrated with webapps that were deployed on Google Firebase. This made deployment 10x faster.",
      textFive: "• Worked closely with doctors during the development process of the 3D Heart Viewers."
      },
    {
      title: "Game Developer/Tester",
      company: 'York University',
      location: 'Toronto, ON',
      date:
        'Jul 2021 - Sept 2021',
      image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Logo_York_University.svg/1200px-Logo_York_University.svg.png",
      imgTitle: "York Emblem",
      text: 
      "• Tested and developed an AR App on both iOS and Android.",
      textTwo: "• Using different pipelines to create “AR Windows” to view objects within the space.",
      textThree: "• Used C# to write scripts for the features, such as AR Portals, creating dynamic animations and for spawning objects.",
      textFour: "• Used ARKit and ARCore for development, and for AR Image Targets.",
    },
    {
      title: "Game Developer",
      company: 'York University',
      location: 'Toronto, ON',
      date:
        'May 2021 - Jun 2021',
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Logo_York_University.svg/1200px-Logo_York_University.svg.png",
      imgTitle: "York Emblem",
      text: 
      "• Used C# to create an object spawner which periodically spawns germs.",
      textTwo: "• Used ARCore for AR functionality.",
      textThree: "• “Proxemics” uses plane detection to create invisible planes. Planes have triggers which spawn different germs",
      textFour: "• When the germs touch the invisible AR Plane, it spawns bacteria. This functionality is done fully with C# and shaders",
      textFive: "• Tested on Android.",
    },
  ];
  
  export default workExp;